.delegation_container {
  max-width: 1000px;
  position: relative;
  text-align: center;
  margin-bottom: 20px;
}

.delegation_container td:nth-child(1) {
  text-align: left;
}

.delegation_container .undelegate_btn {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border: 1px solid black;
  background-color: transparent;
}

.delegation_container > h3 {
  font-size: 20px;
  font-family: "Lena";
}
