@font-face {
  font-family: "Proxima Nova";
  src: url("./fonts/proxima-nova-font.otf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main {
  display: flex;
  justify-content: space-between;
}

a {
  text-decoration: none;
}

.main .left_section {
  flex: 1;
  min-height: calc(90vh - 20px);
}

.main .right_section {
  flex: 2;
}

@media only screen and (max-width: 992px) {
  .main {
    flex-direction: column;
  }
}
