.unwrap_section {
  margin-top: 100px;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.unwrap_section .loading_section {
  position: relative;
}
.unwrap_section .loading_section > .load {
  height: 116px;
  width: 116px;
}

.unwrap_section .loading_section > .logo {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100px;
}

.unwrap_section .input_section {
  margin: 0 auto;
  width: 65%;
  margin: 25px 0 10px 0;
  display: flex;
  border: 1px solid #aaa;
}

.unwrap_section input[type="number"] {
  padding: 22px 24px;
  width: 100%;
  border: 0;
}

.unwrap_section .input_section > button {
  cursor: pointer;
  color: #000;
  border: 0;
  background-color: rgba(255, 255, 255, 0.7);
  color: #1976d2;
  padding: 0.5rem 1rem;
  border-radius: 0;
}

.unwrap_section .input_section > button.none {
  display: none;
}

.unwrap_section .unwrap_btn {
  padding: 20px 60px;
  background-color: black;
  color: white;
  cursor: pointer;
  border: none;
}

.unwrap_section .error_section,
.unwrap_section .success_section {
  text-align: center;
}

.unwrap_section .error_section > *,
.unwrap_section .success_section > * {
  margin: 20px auto;
  display: block;
}

@media only screen and (max-width: 992px) {
  .unwrap_section {
    margin: 30px;
    min-height: 100%;
  }
}
