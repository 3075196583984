.welcome_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
}

.activepop .welcome_container {
  filter: blur(2px);
}
.welcome_container .welcome_section {
  text-align: center;
}

.welcome_section > * {
  margin: 55px;
}

.welcome_section .upper > * {
  margin: 15px;
}
.welcome_section .lower > * {
  margin: 25px;
}

.welcome_section a {
  color: blue;
}

.welcome_section .connectbtn {
  padding: 20px 40px;
  cursor: pointer;
  background-color: black;
  color: white;
  border: none;
}

.pop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: transparent; */
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  backdrop-filter: blur(7px);
  transition: all 1s ease-in-out;
  font-size: 4rem;
}

.pop .btns {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.pop > button.closeBtn {
  position: absolute;
  right: 14%;
  top: 0;
  background: transparent;
  border: none;
  cursor: pointer;
}

.pop .btns > button.connectbtn {
  background-color: transparent;
  margin: 90px;
  font-size: 3rem;
  border: 0;
  cursor: pointer;
  color: gray;
}

.pop .btns > button.connectbtn.FLR:hover,
.pop .btns > button.connectbtn.SGB:hover {
  color: #b35160;
  text-decoration: underline;
}

/* close button */

.outer {
  position: relative;
  margin: auto;
  width: 40px;
  margin-top: 140px;
  cursor: pointer;
}

.inner {
  width: inherit;
  text-align: center;
}

label {
  font-size: 0.8em;
  line-height: 4em;
  text-transform: uppercase;
  color: black;
  transition: all 0.3s ease-in;
  opacity: 0;
  cursor: pointer;
}

.inner:before,
.inner:after {
  position: absolute;
  content: "";
  height: 1px;
  width: inherit;
  background: black;
  left: 0;
  transition: all 0.3s ease-in;
}

.inner:before {
  top: 50%;
  transform: rotate(45deg);
}

.inner:after {
  bottom: 50%;
  transform: rotate(-45deg);
}

.outer:hover label {
  opacity: 1;
}

.outer:hover .inner:before,
.outer:hover .inner:after {
  transform: rotate(0);
}

.outer:hover .inner:before {
  top: 0;
}

.outer:hover .inner:after {
  bottom: 0;
}

/* logo image */

.connectbtn > img {
  filter: grayscale(100%);
}

.connectbtn:hover > img {
  filter: grayscale(0%);
}

@media only screen and (max-width: 992px) {
  .pop .btns > button.connectbtn {
    margin: 70px;
  }
  .pop .btns > .connectbtn > p {
    font-size: 25px;
  }
  .pop .btns > .connectbtn > img {
    width: 70px;
  }
}
