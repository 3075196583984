.delegate_section {
  margin-top: 100px;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.delegate_section .loading_section {
  position: relative;
}

.delegate_section .loading_section > .load {
  height: 116px;
  width: 116px;
}

.delegate_section .loading_section > .logo {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100px;
}

.delegate_section .btns {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.delegate_section .delegate_btn {
  height: 50px;
  width: 235px;
  background-color: black;
  color: white;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 13px;
  font-family: "roboto";
  margin: 10px 20px;
}

.delegate_section .delegate_btn > a {
  text-decoration: none;
  color: white;
}

.delegate_section .error_section,
.delegate_section .success_section {
  text-align: center;
}

.delegate_section .error_section > *,
.delegate_section .success_section > * {
  display: block;
  margin: 30px auto;
}

.delegate_section .text_ftso {
  width: 80%;
  padding: 15px;
  text-align: center;
}

@media only screen and (max-width: 992px) {
  .delegate_section {
    margin: 30px;
    min-height: 100%;
  }
}
