.wrap_section {
  margin-top: 100px;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  position: relative;
}

.wrap_section .loading_section {
  position: relative;
}
.wrap_section .loading_section > .load {
  height: 116px;
  width: 116px;
}
.wrap_section .loading_section > .logo {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100px;
}
.wrap_section .input_section {
  margin: 0 auto;
  width: 65%;
  margin: 25px 0 10px 0;
  display: flex;
  border: 1px solid #aaa;
}

.wrap_section input[type="number"] {
  padding: 22px 24px;
  width: 87%;
  border: 0;
}
.wrap_section .input_section >div{
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrap_section .input_section >div button.max {
  cursor: pointer;
  color: #000;
  border: 0;
  background-color: rgba(255, 255, 255, 0.7);
  color: #1976d2;
  padding: 0.5rem 1rem;
  border-radius: 0;
}

.wrap_section .input_section >div button.none {
  display: none;
}

.wrap_section .wrap_btn {
  padding: 20px 60px;
  background-color: black;
  color: white;
  cursor: pointer;
  border: none;
}

.wrap_section .error_section,
.wrap_section .success_section {
  text-align: center;
}

.wrap_section .error_section > *,
.wrap_section .success_section > * {
  margin: 20px auto;
  display: block;
}

.wrap_section .success_section > a {
  text-decoration: none;
}

.tooltip{
  position: relative;
}

.tooltip:hover:after {
  background: #fff;
  padding: 5px 12px;
  font-size: 12px;
  color: black;
  border: solid 1px #ddd;
  position: absolute;
  font-family: "Lena";
  font-weight: 400;
  bottom: 130%;
  width: 250px;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip.SGB:hover:after{
  content: "We are keeping a bit of SGB aside for you so you can pay for your next transactions";
}

.tooltip.FLR:hover:after{
  content: "We are keeping a bit of FLR aside for you so you can pay for your next transactions";
}
@media only screen and (max-width: 992px) {
  .wrap_section {
    margin: 30px;
    min-height: 100%;
  }
}
