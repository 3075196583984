@font-face {
 font-family:proxima-nova;
 src:url(https://use.typekit.net/af/1be3c2/00000000000000007735e606/30/l?subset_id=2&fvd=n3&v=3) format("woff2"),
 url(https://use.typekit.net/af/1be3c2/00000000000000007735e606/30/d?subset_id=2&fvd=n3&v=3) format("woff"),
 url(https://use.typekit.net/af/1be3c2/00000000000000007735e606/30/a?subset_id=2&fvd=n3&v=3) format("opentype");
 font-weight:300;
 font-style:normal;
 font-display:auto;
}

@font-face {
 font-family:proxima-nova;
 src:url(https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/l?subset_id=2&fvd=n4&v=3) format("woff2"),
 url(https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/d?subset_id=2&fvd=n4&v=3) format("woff"),
 url(https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/a?subset_id=2&fvd=n4&v=3) format("opentype");
 font-weight:400;
 font-style:normal;
 font-display:auto;
}

@font-face {
 font-family:proxima-nova;
 src:url(https://use.typekit.net/af/23e139/00000000000000007735e605/30/l?subset_id=2&fvd=n5&v=3) format("woff2"),
 url(https://use.typekit.net/af/23e139/00000000000000007735e605/30/d?subset_id=2&fvd=n5&v=3) format("woff"),
 url(https://use.typekit.net/af/23e139/00000000000000007735e605/30/a?subset_id=2&fvd=n5&v=3) format("opentype");
 font-weight:500;
 font-style:normal;
 font-display:auto;
}

@font-face {
 font-family:proxima-nova;
 src:url(https://use.typekit.net/af/2555e1/00000000000000007735e603/30/l?subset_id=2&fvd=n7&v=3) format("woff2"),
 url(https://use.typekit.net/af/2555e1/00000000000000007735e603/30/d?subset_id=2&fvd=n7&v=3) format("woff"),
 url(https://use.typekit.net/af/2555e1/00000000000000007735e603/30/a?subset_id=2&fvd=n7&v=3) format("opentype");
 font-weight:700;
 font-style:normal;
 font-display:auto;
}

@font-face {
 font-family:proxima-nova;
 src:url(https://use.typekit.net/af/40d372/00000000000000007735e607/30/l?subset_id=2&fvd=i3&v=3) format("woff2"),
 url(https://use.typekit.net/af/40d372/00000000000000007735e607/30/d?subset_id=2&fvd=i3&v=3) format("woff"),
 url(https://use.typekit.net/af/40d372/00000000000000007735e607/30/a?subset_id=2&fvd=i3&v=3) format("opentype");
 font-weight:300;
 font-style:italic;
 font-display:auto;
}

@font-face {
 font-family:proxima-nova;
 src:url(https://use.typekit.net/af/4de20a/00000000000000007735e604/30/l?subset_id=2&fvd=i7&v=3) format("woff2"),
 url(https://use.typekit.net/af/4de20a/00000000000000007735e604/30/d?subset_id=2&fvd=i7&v=3) format("woff"),
 url(https://use.typekit.net/af/4de20a/00000000000000007735e604/30/a?subset_id=2&fvd=i7&v=3) format("opentype");
 font-weight:700;
 font-style:italic;
 font-display:auto;
}

@font-face {
 font-family:ltc-bodoni-175;
 src:url(https://use.typekit.net/af/04715e/000000000000000077359a54/30/l?subset_id=2&fvd=n4&v=3) format("woff2"),
 url(https://use.typekit.net/af/04715e/000000000000000077359a54/30/d?subset_id=2&fvd=n4&v=3) format("woff"),
 url(https://use.typekit.net/af/04715e/000000000000000077359a54/30/a?subset_id=2&fvd=n4&v=3) format("opentype");
 font-weight:400;
 font-style:normal;
 font-display:auto;
}

@font-face {
 font-family:ltc-bodoni-175;
 src:url(https://use.typekit.net/af/3c382e/000000000000000000010b17/27/l?subset_id=2&fvd=i4&v=3) format("woff2"),
 url(https://use.typekit.net/af/3c382e/000000000000000000010b17/27/d?subset_id=2&fvd=i4&v=3) format("woff"),
 url(https://use.typekit.net/af/3c382e/000000000000000000010b17/27/a?subset_id=2&fvd=i4&v=3) format("opentype");
 font-weight:400;
 font-style:italic;
 font-display:auto;
}

@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;300;500;700&family=Space+Mono:wght@400;700&display=swap);
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


@font-face {
  font-family: 'Lena';
  src: url('fonts/lena.woff2');
  font-weight: 400;
}
