.sidebar_container {
  padding: 15px;
  height: 100%;
  background-color: #f1f1ef;
  display: flex;
  flex-direction: column;
}

.sidebar_container .wallet_content,
.sidebar_container .coin_section,
.sidebar_container .statistics_section,
.sidebar_container .title {
  margin: 10px;
}

.sidebar_container .title {
  font-size: 28px;
  font-family: "Lena";
  font-weight: 500;
  letter-spacing: 0cm;
  line-height: 1.4em;
  margin: 10px 20px;
}

.wallet_content .subtitle,
.coin_section .subtitle,
.statistics_section .subtitle {
  font-family: "Lena";
  font-weight: 500;
  letter-spacing: 0cm;
  color: #777;
  line-height: 1.4em;
  font-size: 14px;
}

.address_section .addressContent {
  width: 100%;
  background-color: white;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.addressContent .copy_address {
  border: none;
  cursor: pointer;
  background-color: transparent;
}
.addressContent svg:hover {
  color: black !important;
}

.coin_section .songBird,
.coin_section .WsongBird {
  width: 100%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 100px 160px 100px 60px;
  background-color: white;
  justify-items: center;
  align-items: center;
  text-align: start;
}

.songBird > img,
.WsongBird > img {
  width: 50px;
  margin: 0 auto;
}

.songBird > span,
.WsongBird > span {
  padding: 20px;
}

.statistics_section .labelInput {
  margin: 6px 0px;
  padding: 0 20px;
}
.statistics_section input[type="text"].inputtext {
  padding: 20px 10px;
  margin: 4px 20px;
  width: 90%;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 15px;
}
.statistics_section input[type="text"].inputtext:active,
.statistics_section input[type="text"].inputtext:focus {
  border: 3px solid black;
}
.statistics_section .statistics_content {
  background-color: white;
  padding: 10px 0px;
}

.statistics_content .unclaimed_rewards,
.statistics_content .pending_rewards {
  margin: 10px 0px;
  display: flex;
  padding: 0px 20px;
  justify-content: space-between;
  align-items: center;
}

.sidebar_container .claim_btn {
  display: block;
  padding: 16px;
  background-color: black;
  color: white;
  width: 175px;
  margin: 0 auto;
  cursor: pointer;
  border: none;
  margin-top: auto;
}

.statistics_content .pending_rewards > div .period,
.statistics_content .unclaimed_rewards > div .period {
  font-style: italic;
  font-size: 80%;
}

@media only screen and (max-width: 992px) {
  .coin_section .songBird,
  .coin_section .WsongBird {
    grid-template-columns: 24% 20% 20% 20%;
  }
}
