.terms .navbar {
  padding: 15px 10px;
}
.terms .navbar a h1 {
  font-size: 25px;
  font-family: ltc-bodoni-175;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0em;
  text-transform: none;
  line-height: 1.4em;
  color: black;
}

.disclaimer {
  width: 100%;
  padding: 15px 100px;
  font-family: "Proxima Nova";
  text-align: justify;
  font-size: 1rem;
  line-height: 1.5;
}

.disclaimer .bold,
.disclaimer span {
  font-weight: 700;
}

.disclaimer > p {
  margin: 20px;
}

.terms .connectbtn {
  display: block;
  margin: 10px auto;
  padding: 20px 40px;
  cursor: pointer;
  background-color: black;
  color: white;
  border: none;
}

@media only screen and (max-width: 992px) {
  .disclaimer {
    padding: 15px;
  }
}

.activepop.terms {
  max-height: 100%;
}
