@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;300;500;700&family=Space+Mono:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@font-face {
 font-family:proxima-nova;
 src:url(https://use.typekit.net/af/1be3c2/00000000000000007735e606/30/l?subset_id=2&fvd=n3&v=3) format("woff2"),
 url(https://use.typekit.net/af/1be3c2/00000000000000007735e606/30/d?subset_id=2&fvd=n3&v=3) format("woff"),
 url(https://use.typekit.net/af/1be3c2/00000000000000007735e606/30/a?subset_id=2&fvd=n3&v=3) format("opentype");
 font-weight:300;
 font-style:normal;
 font-display:auto;
}

@font-face {
 font-family:proxima-nova;
 src:url(https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/l?subset_id=2&fvd=n4&v=3) format("woff2"),
 url(https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/d?subset_id=2&fvd=n4&v=3) format("woff"),
 url(https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/a?subset_id=2&fvd=n4&v=3) format("opentype");
 font-weight:400;
 font-style:normal;
 font-display:auto;
}

@font-face {
 font-family:proxima-nova;
 src:url(https://use.typekit.net/af/23e139/00000000000000007735e605/30/l?subset_id=2&fvd=n5&v=3) format("woff2"),
 url(https://use.typekit.net/af/23e139/00000000000000007735e605/30/d?subset_id=2&fvd=n5&v=3) format("woff"),
 url(https://use.typekit.net/af/23e139/00000000000000007735e605/30/a?subset_id=2&fvd=n5&v=3) format("opentype");
 font-weight:500;
 font-style:normal;
 font-display:auto;
}

@font-face {
 font-family:proxima-nova;
 src:url(https://use.typekit.net/af/2555e1/00000000000000007735e603/30/l?subset_id=2&fvd=n7&v=3) format("woff2"),
 url(https://use.typekit.net/af/2555e1/00000000000000007735e603/30/d?subset_id=2&fvd=n7&v=3) format("woff"),
 url(https://use.typekit.net/af/2555e1/00000000000000007735e603/30/a?subset_id=2&fvd=n7&v=3) format("opentype");
 font-weight:700;
 font-style:normal;
 font-display:auto;
}

@font-face {
 font-family:proxima-nova;
 src:url(https://use.typekit.net/af/40d372/00000000000000007735e607/30/l?subset_id=2&fvd=i3&v=3) format("woff2"),
 url(https://use.typekit.net/af/40d372/00000000000000007735e607/30/d?subset_id=2&fvd=i3&v=3) format("woff"),
 url(https://use.typekit.net/af/40d372/00000000000000007735e607/30/a?subset_id=2&fvd=i3&v=3) format("opentype");
 font-weight:300;
 font-style:italic;
 font-display:auto;
}

@font-face {
 font-family:proxima-nova;
 src:url(https://use.typekit.net/af/4de20a/00000000000000007735e604/30/l?subset_id=2&fvd=i7&v=3) format("woff2"),
 url(https://use.typekit.net/af/4de20a/00000000000000007735e604/30/d?subset_id=2&fvd=i7&v=3) format("woff"),
 url(https://use.typekit.net/af/4de20a/00000000000000007735e604/30/a?subset_id=2&fvd=i7&v=3) format("opentype");
 font-weight:700;
 font-style:italic;
 font-display:auto;
}

@font-face {
 font-family:ltc-bodoni-175;
 src:url(https://use.typekit.net/af/04715e/000000000000000077359a54/30/l?subset_id=2&fvd=n4&v=3) format("woff2"),
 url(https://use.typekit.net/af/04715e/000000000000000077359a54/30/d?subset_id=2&fvd=n4&v=3) format("woff"),
 url(https://use.typekit.net/af/04715e/000000000000000077359a54/30/a?subset_id=2&fvd=n4&v=3) format("opentype");
 font-weight:400;
 font-style:normal;
 font-display:auto;
}

@font-face {
 font-family:ltc-bodoni-175;
 src:url(https://use.typekit.net/af/3c382e/000000000000000000010b17/27/l?subset_id=2&fvd=i4&v=3) format("woff2"),
 url(https://use.typekit.net/af/3c382e/000000000000000000010b17/27/d?subset_id=2&fvd=i4&v=3) format("woff"),
 url(https://use.typekit.net/af/3c382e/000000000000000000010b17/27/a?subset_id=2&fvd=i4&v=3) format("opentype");
 font-weight:400;
 font-style:italic;
 font-display:auto;
}


@font-face {
  font-family: 'Lena';
  src: url(/static/media/lena.aa1ff618.woff2);
  font-weight: 400;
}

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

:root{
  --bg-color: #FFF;
  --bg-transparent: #CCC;
  --blue: #1976D2;
  --pink: #FF005E;
  --orange: #FF8D19;
  --black: #000000;
  --text: #333333;
  --small: small;
  --min-font: 14px;
  --mid-font: 20px;
  --big-font: 27px;
}

h1 {
  font-size: calc((2.8 - 1) * 1.2vw + 1rem);
  font-family: 'Lena';
  font-weight: 300;
  line-height: 1.2992;
  margin: 0;
  text-transform: none;
  white-space: pre-wrap;
}

h2 {
  font-size: calc((1.5 - 1) * 1.2vw + 1rem);
  font-family: proxima-nova;
  font-weight: 300;
  line-height: 1.2992;
  margin: 0;
  text-align: center;
  text-transform: none;
  white-space: pre-wrap;
}

.welcome {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
}

.informer {
  position: absolute;
  width: 38%;
  height: calc(100% - 120px);
  top: 120px;
  left: 0;
  z-index: 500;
  background: #f1f1ef;
  opacity: 1;
}

.informer__wrap {
  padding: 20px;
}

.informer__str {
  background: #FFF;
  display: flex;
  min-height: 60px;
  padding-left: 10px;
  align-items: center;
}

.informer__textname {
  display: inline-block;
  font-size: calc((1.2 - 1) * 1.2vw + 1rem);
  font-family: proxima-nova;
  padding: 0 10px 0 0;
  color: #131313;
}

.informer__textvalue {
  display: inline-block;
  font-size: calc((1.2 - 1) * 1.2vw + 1rem);
  font-family: 'Mono';
}

.informer__textdescr {
  font-size: calc((1.1 - 1) * 1.2vw + 1rem);
  font-family: proxima-nova;
  color: #777;
  font-weight: 400;
  padding-top: 15px;
}

.informer__logo {
  display: inline-block;
}

.informer__input {
  position: absolute;
  width: calc(100% - 10px);
  left: 5px;
  height: 40px;
  text-align: center;
  font-size: 89%;
}

.icon-sgb {
  width: 60px;
  height: 60px;
  margin: 10px 10px 10px 0;
  background: url(/static/media/iconSgb.82252cb6.png) no-repeat;
  background-size: cover;
}

.icon-wsgb {
  width: 60px;
  height: 60px;
  margin: 0 10px 10px 0;
  background: url(/static/media/iconWsgb.7c0f79fd.png) no-repeat;
  background-size: cover;
}

.icon-mm {
  width: 70px;
  height: 70px;
  margin: 10px;
  background: url(/static/media/iconMm.4f06ed22.png) no-repeat;
  background-size: cover;
}

.header__logotext {
  display: inline-block;
  font-size: calc((2 - 1) * 1.2vw + 1rem);
  font-family: 'Lena';
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 1.4em;
}

.header__nav {
  display: inline-block;
}

.header__navlink {
  display: inline-block;
  padding: .1em .5em;
  font-size: calc((1.1 - 1) * 1.2vw + 1rem);
  font-family: proxima-nova;
  font-weight: 300;
  letter-spacing: 0em;
  line-height: 1.8em;
}
.header__navlink:hover {
  cursor: pointer;
}

body {
  margin: 0;
  font-family: 'Roboto';
  overlop-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Space Mono', monospace;
}

.nav-mobile {
  display: block;
  position: relative;
  width: 100%;
  padding: 20px 0;
  font-family: 'Lena';
  text-decoration: none;
  text-align: center;
  font-size: 30px;
  color: #000;
  font-weight: 500;
}

.body__loader{
    width: 150px;
    display: block;
    margin: 0 auto;
}

.footer_text{
    display: flex;
    align-items: center;
    cursor: pointer;
}

.footer__status{
    background-color: #32D74B;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    margin-right: .6rem;
    box-shadow: 1px 1px 15px 1px #32D74B;
    margin-left: 25px;
}

.footer__container p {
    font-size: 8pt;
    font-weight: 300;
}
.disconnected{
    background-color: #E01837;
    box-shadow: 1px 1px 15px 1px #E01837;
}

.header{
    position: fixed;
    width: 100vw;
    background-color: #FCFCFC;
    z-index: 100;
}

.header__container{
}
.disconnect__button{
    height: 1.3rem;
    margin-left: 1rem;
    cursor: pointer;
}

.header__button{
    cursor: pointer;
    background-color: #FFF;
    color: #1976D2;
    color: var(--blue);
    font-family: 'Space Mono', monospace;
    font-size: small;
    font-size: var(--small);
    border: 1px solid #FFF;
    border-radius: 25px;
    padding: .6rem 1.5rem;
    margin-right: 15px;
}
.body{
  height: calc(100% - 120px);
  width: 62%;
  position: absolute;
  top: 120px;
  left: 38%;
  margin: 0;
  padding: 0;
}

.body__container{
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.body__logo{
    height: 3rem;
    margin: 0 auto;
    display: block;
    margin-bottom: 1.2rem;
}

.body__wrap{
    width: 100%;
    padding: 0.8rem 2.8rem;
    padding-bottom: 3rem;
    background-color: #FFF;
    border-radius: 0;
    margin-bottom: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.input__container{
    margin: 0 auto;
    width: 85%;
    margin: 25px 0 10px 0;
    display: flex;
    border: 1px solid #AAA;
}

.body__input{
    display: block;
    width: 100%;
    font-family: 'Roboto';
    font-size: 20px;
    font-size: var(--mid-font);
    font-size: 20px;
    font-size: var(--mid-font);
    color: #000000;
    color: var(--black);
    border: 0;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 1.4rem 1.5rem;
    border-radius: 0;
}

.input__max{
    font-size: small;
    font-size: var(--small);
    color: #000;
    border: 0;
    background-color: rgba(255, 255, 255, 0.7);
    padding: .5rem 1rem;
    border-radius: 0;
    cursor: pointer;
}
.body__button{
    cursor: pointer;
    font-family: proxima-nova;
    font-size: calc((.9 - 1) * 1.2vw + 1rem);
    font-weight: 500;
    font-style: normal;
    letter-spacing: .02em;
    text-transform: none;
    line-height: 1.2em;
    border: 1px solid #000000;
    background-color: #000000;
    color: white;
    padding: 1.4rem 2.338rem;
    border-radius: 0;
    display: block;
    margin: 15px auto;
    text-align: center;
    text-decoration: none;
}

.body__button__completed{
    cursor: pointer;
    font-family: 'Roboto';
    font-size: 20px;
    font-size: var(--mid-font);
    border: 1px solid #000;
    background-color: #000;
    color: white;
    padding: 1rem 3rem;
    width: 85%;
    border-radius: 0;
    display: block;
    margin: 15px auto;
    margin-top: 3rem;
    margin-bottom: 1rem;
    text-align: center;
    text-decoration: none; 
}

/* HIDE DEFAULT INPUT STYLING */
input:focus{
    outline: none;
}
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}

/* STYLE INPUIT TYPE RANGE */
#delegate {
    display: block;
    background: linear-gradient(to right, #1976D2 0%, #1976D2 50%, #0000FF 50%, #0000FF 100%);
    background: linear-gradient(to right, var(--blue) 0%, var(--blue) 50%, #0000FF 50%, #0000FF 100%);
    border-radius: 3px;
    height: 3px;
    width: 80%;
    margin: 0 auto;
    outline: none;
    -webkit-appearance: none;
}

#delegate::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 12px;
    height: 12px;
    border-radius: 50%; 
    background: #1976D2; 
    background: var(--blue);
    cursor: pointer;
}

#delegate::-moz-range-thumb {
    -webkit-appearance: none;
         appearance: none;
    width: 12px;
    height: 12px;
    border-radius: 50%; 
    background: #1976D2; 
    background: var(--blue);
    cursor: pointer;
}

.welcome_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
}

.activepop .welcome_container {
  -webkit-filter: blur(2px);
          filter: blur(2px);
}
.welcome_container .welcome_section {
  text-align: center;
}

.welcome_section > * {
  margin: 55px;
}

.welcome_section .upper > * {
  margin: 15px;
}
.welcome_section .lower > * {
  margin: 25px;
}

.welcome_section a {
  color: blue;
}

.welcome_section .connectbtn {
  padding: 20px 40px;
  cursor: pointer;
  background-color: black;
  color: white;
  border: none;
}

.pop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: transparent; */
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  -webkit-backdrop-filter: blur(7px);
          backdrop-filter: blur(7px);
  transition: all 1s ease-in-out;
  font-size: 4rem;
}

.pop .btns {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.pop > button.closeBtn {
  position: absolute;
  right: 14%;
  top: 0;
  background: transparent;
  border: none;
  cursor: pointer;
}

.pop .btns > button.connectbtn {
  background-color: transparent;
  margin: 90px;
  font-size: 3rem;
  border: 0;
  cursor: pointer;
  color: gray;
}

.pop .btns > button.connectbtn.FLR:hover,
.pop .btns > button.connectbtn.SGB:hover {
  color: #b35160;
  text-decoration: underline;
}

/* close button */

.outer {
  position: relative;
  margin: auto;
  width: 40px;
  margin-top: 140px;
  cursor: pointer;
}

.inner {
  width: inherit;
  text-align: center;
}

label {
  font-size: 0.8em;
  line-height: 4em;
  text-transform: uppercase;
  color: black;
  transition: all 0.3s ease-in;
  opacity: 0;
  cursor: pointer;
}

.inner:before,
.inner:after {
  position: absolute;
  content: "";
  height: 1px;
  width: inherit;
  background: black;
  left: 0;
  transition: all 0.3s ease-in;
}

.inner:before {
  top: 50%;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.inner:after {
  bottom: 50%;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.outer:hover label {
  opacity: 1;
}

.outer:hover .inner:before,
.outer:hover .inner:after {
  -webkit-transform: rotate(0);
          transform: rotate(0);
}

.outer:hover .inner:before {
  top: 0;
}

.outer:hover .inner:after {
  bottom: 0;
}

/* logo image */

.connectbtn > img {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}

.connectbtn:hover > img {
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
}

@media only screen and (max-width: 992px) {
  .pop .btns > button.connectbtn {
    margin: 70px;
  }
  .pop .btns > .connectbtn > p {
    font-size: 25px;
  }
  .pop .btns > .connectbtn > img {
    width: 70px;
  }
}

.navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 999;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  min-height: 10vh;
}

.navbar .right_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar .right_section .logo_navbar {
  font-size: 25px;
  font-family: ltc-bodoni-175;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0em;
  text-transform: none;
  line-height: 1.4em;
}

.navbar .right_section .toggle_button {
  display: none;
}

.navbar .menu_navbar {
  list-style: none;
  display: flex;
}

.navbar .menu_navbar > li,
.btns > button.textImage {
  position: relative;
  margin: 0 20px;
  font-size: 17px;
  text-align: center;
}
.navbar .menu_navbar > li .numberSpan,
.btns > button.textImage .numberSpan {
  padding: 5px;
  background-color: grey;
  width: 25px;
  height: 25px;
  margin: 5px auto;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  font-size: 13px;
  align-items: center;
  cursor: pointer;
}

.btns > button.textImage .image.numberSpan {
  background-color: transparent;
  color: black;
}

.navbar .menu_navbar > li a:hover .numberSpan {
  background-color: #000;
}

.btns > button:hover .image.numberSpan {
  background-color: transparent;
  -webkit-filter: opacity(50%);
          filter: opacity(50%);
}

.menu_navbar > li a,
.btns > button {
  text-decoration: none;
  color: black;
  cursor: pointer;
  font-family: "Lena";
  font-weight: 400;
}

.menu_navbar > li a:hover,
.navbar .btns > button:hover {
  color: gray;
}

.navbar .btns {
  position: relative;
  display: flex;
  align-items: center;
}

.navbar .btns > button {
  background-color: transparent;
  padding: 7px;
  border: none;
  cursor: pointer;
}

.navbar .btns > button img {
  width: 20px;
}

.ball{
    background-color: #32d74b;
    border-radius: 50%;
    box-shadow: 1px 1px 15px 1px #32d74b;
    display: inline-block;
    height: 8px;
    margin: 0 15px 0 10px;
    width: 8px;
}

@media only screen and (max-width: 992px) {
  .navbar {
    display: block;
  }
  .navbar .right_section .toggle_button {
    display: block;
    background-color: transparent;
    padding: 20px;
    border: none;
    cursor: pointer;
  }
  .navbar .menu_navbar {
    flex-direction: column;
    display: none;
  }
  .navbar .menu_navbar > li {
    text-align: center;
    margin: 20px;
  }
  .navbar .btns {
    display: none;
  }

  .navbar.open .menu_navbar {
    display: block;
  }
  .navbar.open .btns {
    display: flex;
    justify-content: center;
  }
  .navbar.open .btns > button {
    display: block;
    width: 150px;
  }
  .navbar .menu_navbar > li .numberSpan {
    display: none;
  }
  .btns > button.textImage p {
    display: none;
  }
  .btns > button.textImage .numberSpan {
    padding: 0;
    width: 20;
    height: 20;
  }
}

.wrap_section {
  margin-top: 100px;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px;
  position: relative;
}

.wrap_section .loading_section {
  position: relative;
}
.wrap_section .loading_section > .load {
  height: 116px;
  width: 116px;
}
.wrap_section .loading_section > .logo {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100px;
}
.wrap_section .input_section {
  margin: 0 auto;
  width: 65%;
  margin: 25px 0 10px 0;
  display: flex;
  border: 1px solid #aaa;
}

.wrap_section input[type="number"] {
  padding: 22px 24px;
  width: 87%;
  border: 0;
}
.wrap_section .input_section >div{
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrap_section .input_section >div button.max {
  cursor: pointer;
  color: #000;
  border: 0;
  background-color: rgba(255, 255, 255, 0.7);
  color: #1976d2;
  padding: 0.5rem 1rem;
  border-radius: 0;
}

.wrap_section .input_section >div button.none {
  display: none;
}

.wrap_section .wrap_btn {
  padding: 20px 60px;
  background-color: black;
  color: white;
  cursor: pointer;
  border: none;
}

.wrap_section .error_section,
.wrap_section .success_section {
  text-align: center;
}

.wrap_section .error_section > *,
.wrap_section .success_section > * {
  margin: 20px auto;
  display: block;
}

.wrap_section .success_section > a {
  text-decoration: none;
}

.tooltip{
  position: relative;
}

.tooltip:hover:after {
  background: #fff;
  padding: 5px 12px;
  font-size: 12px;
  color: black;
  border: solid 1px #ddd;
  position: absolute;
  font-family: "Lena";
  font-weight: 400;
  bottom: 130%;
  width: 250px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.tooltip.SGB:hover:after{
  content: "We are keeping a bit of SGB aside for you so you can pay for your next transactions";
}

.tooltip.FLR:hover:after{
  content: "We are keeping a bit of FLR aside for you so you can pay for your next transactions";
}
@media only screen and (max-width: 992px) {
  .wrap_section {
    margin: 30px;
    min-height: 100%;
  }
}

.sidebar_container {
  padding: 15px;
  height: 100%;
  background-color: #f1f1ef;
  display: flex;
  flex-direction: column;
}

.sidebar_container .wallet_content,
.sidebar_container .coin_section,
.sidebar_container .statistics_section,
.sidebar_container .title {
  margin: 10px;
}

.sidebar_container .title {
  font-size: 28px;
  font-family: "Lena";
  font-weight: 500;
  letter-spacing: 0cm;
  line-height: 1.4em;
  margin: 10px 20px;
}

.wallet_content .subtitle,
.coin_section .subtitle,
.statistics_section .subtitle {
  font-family: "Lena";
  font-weight: 500;
  letter-spacing: 0cm;
  color: #777;
  line-height: 1.4em;
  font-size: 14px;
}

.address_section .addressContent {
  width: 100%;
  background-color: white;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.addressContent .copy_address {
  border: none;
  cursor: pointer;
  background-color: transparent;
}
.addressContent svg:hover {
  color: black !important;
}

.coin_section .songBird,
.coin_section .WsongBird {
  width: 100%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 100px 160px 100px 60px;
  background-color: white;
  justify-items: center;
  align-items: center;
  text-align: start;
}

.songBird > img,
.WsongBird > img {
  width: 50px;
  margin: 0 auto;
}

.songBird > span,
.WsongBird > span {
  padding: 20px;
}

.statistics_section .labelInput {
  margin: 6px 0px;
  padding: 0 20px;
}
.statistics_section input[type="text"].inputtext {
  padding: 20px 10px;
  margin: 4px 20px;
  width: 90%;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 15px;
}
.statistics_section input[type="text"].inputtext:active,
.statistics_section input[type="text"].inputtext:focus {
  border: 3px solid black;
}
.statistics_section .statistics_content {
  background-color: white;
  padding: 10px 0px;
}

.statistics_content .unclaimed_rewards,
.statistics_content .pending_rewards {
  margin: 10px 0px;
  display: flex;
  padding: 0px 20px;
  justify-content: space-between;
  align-items: center;
}

.sidebar_container .claim_btn {
  display: block;
  padding: 16px;
  background-color: black;
  color: white;
  width: 175px;
  margin: 0 auto;
  cursor: pointer;
  border: none;
  margin-top: auto;
}

.statistics_content .pending_rewards > div .period,
.statistics_content .unclaimed_rewards > div .period {
  font-style: italic;
  font-size: 80%;
}

@media only screen and (max-width: 992px) {
  .coin_section .songBird,
  .coin_section .WsongBird {
    grid-template-columns: 24% 20% 20% 20%;
  }
}

.unwrap_section {
  margin-top: 100px;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px;
}

.unwrap_section .loading_section {
  position: relative;
}
.unwrap_section .loading_section > .load {
  height: 116px;
  width: 116px;
}

.unwrap_section .loading_section > .logo {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100px;
}

.unwrap_section .input_section {
  margin: 0 auto;
  width: 65%;
  margin: 25px 0 10px 0;
  display: flex;
  border: 1px solid #aaa;
}

.unwrap_section input[type="number"] {
  padding: 22px 24px;
  width: 100%;
  border: 0;
}

.unwrap_section .input_section > button {
  cursor: pointer;
  color: #000;
  border: 0;
  background-color: rgba(255, 255, 255, 0.7);
  color: #1976d2;
  padding: 0.5rem 1rem;
  border-radius: 0;
}

.unwrap_section .input_section > button.none {
  display: none;
}

.unwrap_section .unwrap_btn {
  padding: 20px 60px;
  background-color: black;
  color: white;
  cursor: pointer;
  border: none;
}

.unwrap_section .error_section,
.unwrap_section .success_section {
  text-align: center;
}

.unwrap_section .error_section > *,
.unwrap_section .success_section > * {
  margin: 20px auto;
  display: block;
}

@media only screen and (max-width: 992px) {
  .unwrap_section {
    margin: 30px;
    min-height: 100%;
  }
}

.delegation_section {
  margin-top: 100px;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px;
  position: relative;
}

.delegation_section.list {
  justify-content: flex-start;
}

.delegation_section .loading_section {
  position: relative;
}
.delegation_section .loading_section > .load {
  height: 116px;
  width: 116px;
}

.delegation_section .loading_section > .logo {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100px;
}

.delegation_section .delegation_title {
  font-size: calc((2.8 - 1) * 1.2vw + 1rem);
  font-weight: 300;
}

.delegation_section .error_section,
.delegation_section .success_section {
  text-align: center;
}

.delegation_section .error_section > *,
.delegation_section .success_section > * {
  display: block;
  margin: 30px auto;
}

.delegation_section .redirect_btn,
.delegation_section .redirect_btn a {
  height: 50px;
  width: 235px;
  background-color: black;
  color: white;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 13px;
  font-family: "roboto";
  margin: 10px 20px;
}

/* Table style */

.delegation_section table {
  margin: 0 auto;
  position: relative;
  border: none;
  width: 90%;
}

.delegation_section table > thead {
  padding: 18px 0 !important;
  color: white;
  background-color: black;
}

.delegation_section table > thead th {
  padding: 18px;
  text-align: center;
  min-width: 50px;
  width: 15%;
}

.delegation_section table > thead th:nth-child(1) {
  text-align: left;
}
.delegation_section table > tbody td {
  padding: 18px;
  position: relative;
  text-align: center;
  min-width: 50px;
  width: 15%;
}

.delegation_section table > tbody td:nth-child(5) {
  display: flex;
  justify-content: center;
  width: 100%;
}

.delegation_section table > tbody tr:nth-of-type(odd) {
  background: #eeeeee;
}

.delegation_section .btns {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 992px) {
  .delegation_section {
    margin: 30px;
    min-height: 100%;
  }
}

@media screen and (max-width: 600px) {
  .delegation_section table {
    font-size: 12px;
  }
  .delegation_section table > tbody td:nth-child(4) button {
    font-size: 10px;
    height: 20px;
    width: 20px;
  }
}

.delegation_container {
  max-width: 1000px;
  position: relative;
  text-align: center;
  margin-bottom: 20px;
}

.delegation_container td:nth-child(1) {
  text-align: left;
}

.delegation_container .undelegate_btn {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border: 1px solid black;
  background-color: transparent;
}

.delegation_container > h3 {
  font-size: 20px;
  font-family: "Lena";
}

.delegate_section {
  margin-top: 100px;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px;
}

.delegate_section .loading_section {
  position: relative;
}

.delegate_section .loading_section > .load {
  height: 116px;
  width: 116px;
}

.delegate_section .loading_section > .logo {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100px;
}

.delegate_section .btns {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.delegate_section .delegate_btn {
  height: 50px;
  width: 235px;
  background-color: black;
  color: white;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 13px;
  font-family: "roboto";
  margin: 10px 20px;
}

.delegate_section .delegate_btn > a {
  text-decoration: none;
  color: white;
}

.delegate_section .error_section,
.delegate_section .success_section {
  text-align: center;
}

.delegate_section .error_section > *,
.delegate_section .success_section > * {
  display: block;
  margin: 30px auto;
}

.delegate_section .text_ftso {
  width: 80%;
  padding: 15px;
  text-align: center;
}

@media only screen and (max-width: 992px) {
  .delegate_section {
    margin: 30px;
    min-height: 100%;
  }
}

.terms .navbar {
  padding: 15px 10px;
}
.terms .navbar a h1 {
  font-size: 25px;
  font-family: ltc-bodoni-175;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0em;
  text-transform: none;
  line-height: 1.4em;
  color: black;
}

.disclaimer {
  width: 100%;
  padding: 15px 100px;
  font-family: "Proxima Nova";
  text-align: justify;
  font-size: 1rem;
  line-height: 1.5;
}

.disclaimer .bold,
.disclaimer span {
  font-weight: 700;
}

.disclaimer > p {
  margin: 20px;
}

.terms .connectbtn {
  display: block;
  margin: 10px auto;
  padding: 20px 40px;
  cursor: pointer;
  background-color: black;
  color: white;
  border: none;
}

@media only screen and (max-width: 992px) {
  .disclaimer {
    padding: 15px;
  }
}

.activepop.terms {
  max-height: 100%;
}

@font-face {
  font-family: "Proxima Nova";
  src: url(/static/media/proxima-nova-font.7bc9ce40.otf);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main {
  display: flex;
  justify-content: space-between;
}

a {
  text-decoration: none;
}

.main .left_section {
  flex: 1 1;
  min-height: calc(90vh - 20px);
}

.main .right_section {
  flex: 2 1;
}

@media only screen and (max-width: 992px) {
  .main {
    flex-direction: column;
  }
}

