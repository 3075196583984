.navbar {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 999;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  min-height: 10vh;
}

.navbar .right_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar .right_section .logo_navbar {
  font-size: 25px;
  font-family: ltc-bodoni-175;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0em;
  text-transform: none;
  line-height: 1.4em;
}

.navbar .right_section .toggle_button {
  display: none;
}

.navbar .menu_navbar {
  list-style: none;
  display: flex;
}

.navbar .menu_navbar > li,
.btns > button.textImage {
  position: relative;
  margin: 0 20px;
  font-size: 17px;
  text-align: center;
}
.navbar .menu_navbar > li .numberSpan,
.btns > button.textImage .numberSpan {
  padding: 5px;
  background-color: grey;
  width: 25px;
  height: 25px;
  margin: 5px auto;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  font-size: 13px;
  align-items: center;
  cursor: pointer;
}

.btns > button.textImage .image.numberSpan {
  background-color: transparent;
  color: black;
}

.navbar .menu_navbar > li a:hover .numberSpan {
  background-color: #000;
}

.btns > button:hover .image.numberSpan {
  background-color: transparent;
  filter: opacity(50%);
}

.menu_navbar > li a,
.btns > button {
  text-decoration: none;
  color: black;
  cursor: pointer;
  font-family: "Lena";
  font-weight: 400;
}

.menu_navbar > li a:hover,
.navbar .btns > button:hover {
  color: gray;
}

.navbar .btns {
  position: relative;
  display: flex;
  align-items: center;
}

.navbar .btns > button {
  background-color: transparent;
  padding: 7px;
  border: none;
  cursor: pointer;
}

.navbar .btns > button img {
  width: 20px;
}

.ball{
    background-color: #32d74b;
    border-radius: 50%;
    box-shadow: 1px 1px 15px 1px #32d74b;
    display: inline-block;
    height: 8px;
    margin: 0 15px 0 10px;
    width: 8px;
}

@media only screen and (max-width: 992px) {
  .navbar {
    display: block;
  }
  .navbar .right_section .toggle_button {
    display: block;
    background-color: transparent;
    padding: 20px;
    border: none;
    cursor: pointer;
  }
  .navbar .menu_navbar {
    flex-direction: column;
    display: none;
  }
  .navbar .menu_navbar > li {
    text-align: center;
    margin: 20px;
  }
  .navbar .btns {
    display: none;
  }

  .navbar.open .menu_navbar {
    display: block;
  }
  .navbar.open .btns {
    display: flex;
    justify-content: center;
  }
  .navbar.open .btns > button {
    display: block;
    width: 150px;
  }
  .navbar .menu_navbar > li .numberSpan {
    display: none;
  }
  .btns > button.textImage p {
    display: none;
  }
  .btns > button.textImage .numberSpan {
    padding: 0;
    width: 20;
    height: 20;
  }
}
