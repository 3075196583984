.delegation_section {
  margin-top: 100px;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  position: relative;
}

.delegation_section.list {
  justify-content: flex-start;
}

.delegation_section .loading_section {
  position: relative;
}
.delegation_section .loading_section > .load {
  height: 116px;
  width: 116px;
}

.delegation_section .loading_section > .logo {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100px;
}

.delegation_section .delegation_title {
  font-size: calc((2.8 - 1) * 1.2vw + 1rem);
  font-weight: 300;
}

.delegation_section .error_section,
.delegation_section .success_section {
  text-align: center;
}

.delegation_section .error_section > *,
.delegation_section .success_section > * {
  display: block;
  margin: 30px auto;
}

.delegation_section .redirect_btn,
.delegation_section .redirect_btn a {
  height: 50px;
  width: 235px;
  background-color: black;
  color: white;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 13px;
  font-family: "roboto";
  margin: 10px 20px;
}

/* Table style */

.delegation_section table {
  margin: 0 auto;
  position: relative;
  border: none;
  width: 90%;
}

.delegation_section table > thead {
  padding: 18px 0 !important;
  color: white;
  background-color: black;
}

.delegation_section table > thead th {
  padding: 18px;
  text-align: center;
  min-width: 50px;
  width: 15%;
}

.delegation_section table > thead th:nth-child(1) {
  text-align: left;
}
.delegation_section table > tbody td {
  padding: 18px;
  position: relative;
  text-align: center;
  min-width: 50px;
  width: 15%;
}

.delegation_section table > tbody td:nth-child(5) {
  display: flex;
  justify-content: center;
  width: 100%;
}

.delegation_section table > tbody tr:nth-of-type(odd) {
  background: #eeeeee;
}

.delegation_section .btns {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 992px) {
  .delegation_section {
    margin: 30px;
    min-height: 100%;
  }
}

@media screen and (max-width: 600px) {
  .delegation_section table {
    font-size: 12px;
  }
  .delegation_section table > tbody td:nth-child(4) button {
    font-size: 10px;
    height: 20px;
    width: 20px;
  }
}
